@import 'bootstrap-custom';
@import 'media';
@import 'root';
@import 'fonts';
@import 'typography';

* {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0;
}
.auth-layout {
  align-items: center;
  background-image: url('../img/background-login.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: relative;
  width: 100vw;
}
.auth-container {
  background-color: var(--bs-white);
  border-radius: var(--border-radius);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.35);
  padding: 26px;
  position: relative;
  width: 327px;
  @include media(">phone") {
    width: 408px;
  }
  .auth-logo {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    padding: 20px 14px 0;
  }
  .auth-title {
    color: var(--bs-primary);
    margin-bottom: 10px;
    max-width: 230px;
  }
  .auth-subtitle {
    color: var(--bs-gray-700);
    margin-bottom: 10px;
  }
}
.header {
  align-items: center;
  background-color: var(--bs-white);
  display: flex;
  height: 64px;
  justify-content: space-between;
  left: 0;
  padding: 8px 20px;
  position: fixed;
  right: 0;
  z-index: 999;
  @include media(">desktop") {
    background-color: var(--bs-gray-100);
    height: var(--header-height);
    padding: 0 1rem;
    position: relative;
  }
}
.header-left-section {
  @include media(">desktop") {
    align-items: center;
    display: flex;
  }
}
.mobile-menu-container {
  background-color: var(--bs-primary);
  bottom: 0;
  color: var(--bs-white);
  left: 0;
  padding: 0 24px 24px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}
.mobile-menu-header {
  align-items: center;
  display: flex;
  height: 64px;
  justify-content: space-between;
  margin-bottom: 16px;
}
.menu-header-container {
  @include media(">desktop") {
    height: 100%;
  }
}
.menu-header-item {
  @include media(">desktop") {
    height: 100%;
  }
}
.menu-header-link {
  color: var(--bs-white);
  display: block;
  margin-bottom: 1rem;
  @include media("<phone") {
    @include title3;
  }
  @include media(">desktop") {
    align-items: center;
    border-top: 4px solid transparent;
    color: var(--bs-primary);
    display: flex;
    height: 100%;
    margin-bottom: 0;
    margin-right: 2rem;
    opacity: 0.75;
    @include regular;
    &.active {
      border-top: 4px solid var(--bs-primary);
      opacity: 1;
    }
  }
}
.menu-header-list-container {
  list-style: none;
  @include media(">desktop") {
    align-items: center;
    display: flex;
    height: 100%;
  }
}
.menu-left-container {
  background-color: var(--bs-primary);
  border-radius: var(--border-radius);
  display: flex;
  flex: 0 0 308px;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin-right: 1rem;
  overflow: auto;
  padding: 1rem 0 24px;
}
.menu-left-list-container {
  list-style: none;
  margin-bottom: 42px;
}
.menu-left-link {
  align-items: center;
  color: var(--bs-white);
  display: flex;
  margin-bottom: 20px;
  @include regular;
  svg {
    margin-right: 1rem;
  }
  @include media(">desktop") {
    border-bottom: 1px solid var(--bs-primary-very-light);
    margin-bottom: 0;
    opacity: 0.7;
    padding: 13px 24px;
    &:hover {
      background-color: var(--bs-primary-light);
      color: var(--bs-white);
      opacity: 1;
    }
    &.active {
      background-color: var(--bs-primary-light);
      opacity: 1;
    }
  }
}
.submenu-left-link {
  align-items: center;
  color: var(--bs-white);
  display: flex;
  margin-bottom: 20px;
  margin-left: 38px;
  @include small;
  svg {
    margin-right: 0.5rem;
  }
  @include media(">desktop") {
    margin-bottom: 0;
    margin-left: 0;
    opacity: 0.7;
    padding: 13px 24px 13px 61px;

    &:hover {
      background-color: var(--bs-primary-light);
      color: var(--bs-white);
      opacity: 1;
    }

    &.active {
      background-color: var(--bs-primary-light);
      opacity: 1;
    }
  }
}
.submenu-left-list-container {
  list-style: none;
  @include media(">desktop") {
    background-color: var(--bs-primary-dark);
    border-bottom: 1px solid var(--bs-primary-very-light);
  }
}
.base-layout-container {
  background-color: var(--bs-gray-100);
  display: flex;
  flex-direction: column;
  padding-top: var(--header-height);
  @include media(">desktop") {
    flex-direction: row;
    height: calc(100vh - var(--header-height));
    padding: 8px 16px 16px;
    width: 100%;
  }
}
.dashboard-container {
  display: flex;
  flex-direction: column;
  padding-top: var(--mobile-tab-height);
  @include media(">desktop") {
    flex-direction: row;
    padding-top: 0;
  }
}
.myfarm-container {
  color: var(--bs-white);
  margin: 1rem;
  @include media(">desktop") {
    display: flex;
    margin: 0 1rem 1rem 0;
    width: 58%;
  }
  .myfarm-infos {
    background-color: var(--bs-primary);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    padding: 16px 20px;
    @include media(">desktop") {
      border-radius: var(--border-radius) 0 0 var(--border-radius);
      width: 50%;
    }
  }
  .myfarm-quality {
    background-color: var(--bs-primary-light);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    padding: 16px 20px;
    @include media(">desktop") {
      border-radius: 0 var(--border-radius) var(--border-radius) 0;
      flex-grow: 1;
      width: 50%;
    }
  }
}
.carbonlabelling-container {
  border-radius: var(--border-radius);
  background-color: var(--bs-primary-light);
  color: var(--bs-white);
  margin: 0 1rem 1rem;
  padding: 16px 20px;
  @include media(">desktop") {
    margin-left: 0;
    margin-right: 0;
    width: 42%;
  }
}
.carbonlabelling-steps {
  list-style: none;
  margin: 0;
  padding: 0;
}
.mobile-tab-container {
  background-color: var(--bs-white);
  height: var(--mobile-tab-height);
  left: 0;
  position: fixed;
  right: 0;
  z-index: 999;
  .mobile-tab-items {
    align-items: center;
    display: flex;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    .mobile-tab-item {
      height: 100%;
      width: 33%;
      .mobile-tab-link {
        align-items: center;
        border-bottom: 1px solid var(--bs-gray-200);
        color: var(--bs-gray-700);
        display: flex;
        height: 100%;
        line-height: 150%;
        padding: 0 12px;
        &.active {
          border-bottom: 1px solid var(--bs-primary);
          color: var(--bs-primary);
        }
      }
    }
  }
}
.dashboard-sections-container {
  padding-top: var(--mobile-tab-height);
  .important-info-container {
    background-color: var(--bs-white);
    @include media(">desktop") {
      display: flex;
      border-radius: var(--border-radius);
      flex-direction: column;
      overflow: auto;
      width: 50%;
    }
  }
  @include media(">desktop") {
    display: flex;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
    flex-grow: 1;
    gap: 1rem;
    overflow: auto;
    padding-top: 0;
  }
}
.dashboard-section-header-container {
  display: flex;
  height: 90px;
  .dashboard-section-header-icon-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 88px;
    .dashboard-section-header-icon {
      align-items: center;
      border-radius: 50%;
      color: var(--bs-white);
      display: flex;
      height: 48px;
      justify-content: center;
      width: 48px;
    }
  }
  .dashboard-section-header-title {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
}
.menu-user-container {
  background-color: var(--bs-white);
  border: 1px solid var(--bs-gray-200);
  border-radius: 3px;
  position: fixed;
  right: 10px;
  top: 48px;
  width: 214px;
  z-index: 9998;
  @include media(">desktop") {
    top: 64px;
  }
  .menu-user-list-container {
    list-style: none;
    margin: 0;
    padding: 0;
    .menu-user-item {
      cursor: pointer;
      padding: 16px;
      &:last-of-type {
        border-top: 1px solid var(--bs-gray-200);
      }
    }
  }
}
.content-header-flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.comments-link {
  color: var(--bs-gray-700);
  &.has-comment {
    color: var(--bs-primary);
  }
}
.important-info-page-content {
  padding: 1rem;
  @include media(">desktop") {
    padding: 1rem var(--content-px);
  }
}
.comments-container {
  background-color: var(--bs-gray-200);
  padding: 36px 1rem;
  @include media(">desktop") {
    padding: 36px var(--content-px);
  }
  .comments-submit-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    .comments-submit-text {
      margin-bottom: 12px;
      @include media(">desktop") {
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }
    @include media(">desktop") {
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
  .comments-content-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    .comments-comment-container {
      align-items: center;
      background-color: var(--bs-white);
      border-radius: var(--border-radius);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 8px;
      padding: 1rem;
      width: 100%;
      &.answer {
        width: 80%;
      }
      .comments-comment-arrow-reply {
        border: 1px solid var(--bs-primary);
        border-radius: var(--border-radius);
        cursor: pointer;
        padding: 5px 13px;
      }
      .comments-comment-form-reply {
        display: none;
        flex-basis: 100%;
        margin-top: 1rem;
        &.active {
          display: block;
        }
      }
    }
  }
}
.page-search-form {
  width: 300px;
  .page-search-form-cross {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 13px;
    z-index: 3;
  }
}
.input-group-text {
  background-color: var(--bs-white);
  padding-left: 10px;
  padding-right: 10px;
  & + .form-control {
    border-left: none;
    padding-left: 0;
  }
}
.table-thead, th {
  @extend .regular;
  border-color: var(--bs-gray-400);
  color: var(--bs-gray-400);
}
.table-td-date {
  @extend .regular;
  color: var(--bs-gray-600);
  white-space: nowrap;
}
.introjs-tooltip {
  min-width: 320px;
}
.introjs-prevbutton, .introjs-nextbutton {
  @extend .btn;
  @extend .regular;
  @extend .text-white;
  @include button-variant($success, $success);
  text-shadow: none;
}
@mixin introjs-focus {
  background-color: var(--#{$prefix}btn-bg);
  border-color: var(--#{$prefix}btn-hover-border-color);
  @if $enable-shadows {
    box-shadow: var(--#{$prefix}btn-box-shadow), var(--#{$prefix}btn-focus-box-shadow);
  } @else {
    box-shadow: var(--#{$prefix}btn-focus-box-shadow);
  }
}
.introjs-prevbutton {
  &.introjs-prevbutton:focus {
    @include introjs-focus;
  }
}
.introjs-nextbutton {
  &.introjs-nextbutton:focus {
    @include introjs-focus;
  }
}
.introjs-disabled {
  opacity: 0.65;
}
.introjs-tooltip-title {
  @extend .title3;
  color: var(--bs-gray-900);
}
.introjs-tooltiptext {
  @extend .regular;
  color: var(--bs-gray-900);
  line-height: 150%;
}
.agenda-container {
  padding: 1rem;
  @include media(">desktop") {
    align-items: flex-start;
    display: flex;
    height: 100%;
    overflow: auto;
    padding: 0;
  }
}
.highlighted-event-container {
  margin-top: 16px;
  @include media(">desktop") {
    margin-top: 0;
  }
}
.hilighted-event-card-container {
  background-color: var(--bs-success);
  border-radius: var(--border-radius);
  min-height: 178px;
  padding: 1rem;
  width: 100%;
  @include media(">desktop") {
    margin-top: 0;
    width: 417px;
  }
}
.agenda-month-container {
  margin-top: 50px;
  @include media(">desktop") {
    margin-left: 22px;
    margin-top: 0;
  }
}
.agenda-month-card {
  background-color: var(--bs-white);
  border-radius: var(--border-radius);
  margin-bottom: 0.5rem;
  min-height: 95px;
  opacity: 0.5;
  padding: 1rem;
  width: 100%;
  @include media(">desktop") {
    justify-content: space-between;
    height: 156px;
    margin-bottom: 0;
    overflow-y: auto;
    flex-basis: 24%;
  }
  &.has-event {
    opacity: 1;
  }
}
.agenda-months {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.cursor-pointer {
  cursor: pointer;
}
.mobile-tabs {
  padding-top: var(--mobile-tab-height);
  @include media(">desktop") {
    padding-top: 0;
  }
}
.content-container {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  @include media(">desktop") {
    background-color: var(--bs-white);
    border-radius: var(--border-radius);
    height: 100%;
    overflow: auto;
  }
}
.content-header {
  background-color: var(--bs-white);
  margin-top: 1rem;
  padding: 1rem;
  position: sticky;
  top: var(--header-height);
  z-index: 1;
  @include media(">desktop") {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    margin-top: 0;
    padding: 1rem 0;
    top: 0;
  }
}
.single-container {
  @extend .content-container;
  // display: flex;
  // flex-direction: column;
  @include media(">desktop") {
    margin: 0 auto;
    width: 720px;
  }
  .single-header {
    @extend .content-header;
    align-items: center;
    display: flex;
    height: var(--single-header-height);
    justify-content: space-between;
  }
  .single-content {
    padding: 1rem 0;
  }
}
.bg-light-success {
  background-color: #F4F6EA;
}
.content-header-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  .dropdown,
  .btn-reset-filter {
    margin-top: 0.5rem;
    @include media(">desktop") {
      margin-top: 0;
    }
  }
  .dropdown {
    margin-left: 0;
    @include media(">desktop") {
      margin-left: 1rem;
    }
  }
  .btn-reset-filter {
    margin-left: 1rem;
  }
  .display-all-documents-switch {
    margin-top: 0.5rem;
    @include media(">desktop") {
      margin-bottom: 0;
    }
  }
}
.content-header-title-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  .add-document-btn {
    margin-bottom: 0.5rem;
    @include media(">desktop") {
      margin-bottom: 0;
    }
  }
}
.content-header-title {
  @extend .title3;
  @extend .text-900;
  margin-bottom: 1.19rem;
}
.menu-farm-detail-container {
  align-items: center;
  border-top: 1px solid var(--bs-gray-200);
  display: flex;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
  .menu-farm-detail-link {
    padding: 30px 0;
    text-align: center;
  }
}
.z-full {
  z-index: 9999;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.wagtail {
  p {
    @extend .regular;
  }
  h2 {
    @extend .title2;
  }
  h3 {
    @extend .title3;
  }
}
.map-container {
  height: 400px;
}
.iframe {
  display: block;
  min-height: calc(100% - 64px - 1rem);
  margin-top: 1rem;
  width: 100%;
}
.content-with-single-header {
  height: calc(100% - var(--single-header-height));
  padding: 0.5rem 0;
  position: relative;
  width: 100%;
}
.hide {
  display: none;
}
.document-loader {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.document-display-name {
  flex: 1;
}
.planning-footer-container {
  background-color: var(--bs-primary);
  border-radius: var(--border-radius);
  color: var(--bs-white);
  padding: 24px;
  @include media(">desktop") {
    align-items: flex-start;
    display: flex;
  }
  .planning-footer-meta-block {
    margin-bottom: 1rem;
    @include media(">desktop") {
      margin-bottom: 0;
      margin-right: 1.5rem;
    }
  }
}
.planning-calendar-container {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  flex: 1;
  .planning-calendar-header {
    height: 48px;
    padding: 0 0.5rem 0.5rem;
  }
  .planning-calendar-body {
    background-color: var(--bs-gray-100);
    overflow: auto;
    .planning-days-container {
      display: flex;
      &:nth-of-type(odd) {
        .planning-day-container {
          background-color: var(--bs-gray-200);
          color: var(--bs-white);
        }
      }
      .planning-day-container {
        align-items: center;
        background-color: var(--bs-white);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        color: var(--bs-gray-200);
        display: flex;
        flex: 0 0 34px;
        height: 44px;
        justify-content: center;
        text-align: center;
        &:not(:last-of-type) {
          border-right: 1px solid rgba(0, 0, 0, 0.1);
        }
        &.active {
          background-color: var(--bs-success);
          color: var(--bs-white);
        }
      }
    }
  }
}
.planning-calendar-arrow {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 40px;
}
.planning-parcels-container {
  background-color: var(--bs-gray-100);
  margin-top: calc(48px + 44px);
  width: 105px;
  @include media(">desktop") {
    width: 182px;
  }
  .planning-parcels-add-container {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 48px;
  }
  .planning-parcel-container {
    align-items: center;
    background-color: var(--bs-white);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    min-height: 67px;
    padding: 12px;
    &:nth-of-type(even) {
      background-color: var(--bs-gray-200);
    }
    .planning-parcel-meta {
      flex: 1;
    }
  }
}
button:focus {
  box-shadow: none !important;
}
.planning-filters-container {
  display: flex;
  @include media(">desktop") {
    margin-left: calc(182px + 40px);
  }

}
.planning-container {
  background-color: var(--bs-white);
}

.mobile-diplay-none {
  display: none;

  @include media(">desktop") {
    display: initial;
  }
}
.auto-diag-checkbox-read {
  input, label {
    opacity: 1 !important;
    &[type=checkbox]:checked {
      background-color: var(--bs-primary) !important;
    }
  }
}
.auto-diag-update-container {
  background-color: var(--bs-gray-100);
  height: 100vh;
  overflow: hidden;
}
.auto-diag-update-header-container {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 30px 22px;
}
.auto-diag-update-content-container {
  display: flex;
  height: calc(100% - 83px);
}
.auto-diag-update-form-container {
  flex: 1;
  overflow: auto;
  padding: 1rem;
}
.auto-diag-nav-left-item {
  align-items: center;
  border-left: 4px solid transparent;
  color: var(--bs-gray-700);
  display: flex;
  height: 100%;
  padding-left: 12px;
  width: 100%;
  &.active {
    border-left: 4px solid var(--bs-primary);
    color: var(--bs-primary);
    @extend .small-bold;
  }
}
.form-group-container {
  background-color: var(--bs-gray-200);
  max-width: 800px;
  padding: 1rem;
}
.field-array-container {
  max-width: 800px;
}
.max-width-textarea {
  @extend .field-array-container;
}
.max-width-input-text {
  max-width: 400px;
}
.max-width-input-tel {
  max-width: 180px;
}
.max-width-input-date {
  @extend .max-width-input-tel;
}
.max-width-input-email {
  max-width: 300px;
}
input[disabled] {
  background-color: hsl(0, 0%, 95%) !important;
}
.update-risk-button-container {
  background-color: var(--bs-white);
  position: sticky;
  top: 83px;
  z-index: 2;
}
.w-45 {
  width: 45%;
}
.own-message {
  align-self: end;
  background-color: var(--bs-success);
  border-radius: var(--border-radius);
  color: var(--bs-white);
  padding: 1rem;
  a {
    @extend .btn-outline-light
  }
}
.sender-message {
  align-self: start;
  background-color: var(--bs-gray-200);
  border-radius: var(--border-radius);
  color: var(--bs-gray-900);
  padding: 1rem;
  a {
      @extend .btn-outline-dark
    }
}
.message {
  width: 100%;
  @include media(">desktop") {
    width: 45%;
  }
}
.actu-link {
  background-color: var(--bs-white);
  border-radius: var(--border-radius);
  display: block;
  margin-bottom: 0.5rem;
}
.actu-featured-img {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  width: 100%;
}
.wagtail-content ul {
  margin: 0 1rem 1rem;
  padding-left: 2rem;
}
.advertisement-card-container {
  background-color: var(--bs-white);
  border-radius: var(--border-radius);
  display: block;
  margin-bottom: 1rem;
  height: 350px;
  width: 340px;
}
.advertisement-featured-img-container {
  align-items: center;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  display: flex;
  height: 160px;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  img {
    max-width: 100%;
  }
}
.advertisements-container {
  flex-wrap: wrap;
  gap: 1rem;
  height: 100%;
}
.advertisement-contact {
  background-color: var(--bs-primary);
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
  padding: 1rem;
}
.profile-form-container {
  margin: 1rem auto;
  max-width: 408px;
}
.videos-container {
  flex-wrap: wrap;
  gap: 1rem;
  height: 100%;
}
